import { Component, Vue } from 'vue-property-decorator'
import GoogleLogin from 'vue-google-login'
import { GoogleAuthResponse } from '@/store/modules/auth/Authentication.module'

type GoogleUser = {
  getAuthResponse: () => GoogleAuthResponse;
}

@Component({
  components: {
    GoogleLogin
  }
})
export default class Login extends Vue {
  private client_id = process.env.VUE_APP_GOOGLE_CLIENT_ID

  private renderParams = {
    width: 250,
    height: 50,
    longtitle: true
  }

  private onSuccess (googleUser: GoogleUser) {
    this.$store.commit('storeGoogleUser', googleUser.getAuthResponse())
    const path = this.$route.query.redirect ? this.$route.query.redirect : '/templates/index'
    this.$router.push({ path: path as string })
  }
}
